import React, { useContext } from 'react'
import { styled } from 'linaria/react'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import { CartAlt, ArrowRight } from 'iconoir-react'
import VbButton from '../SiteButton'
import { theme } from '../../Theme'
import Image from '@jetshop/ui/Image/Image'
import {
   getShippingEstimateMessage,
   getStickersFromProductSettings,
   stripQueryParamFromUrl,
} from '../Util/Helpers'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import PriceSection from './PriceSection'
import { useIntl } from '@jetshop/intl'
import Link from '../Link'
import { useAddToCart } from '../../../hooks/useAddToCart'
import { useHistory } from 'react-router-dom'
import PriceSectionWithLabel from './PriceSectionWithLabel'


const ProductCardContainer = styled.div`
   position: relative;
   display: flex;
   min-height: 70px;
   width: 100%;
   /* border-bottom: 1px solid var(--color-separator); */

   .stickers-container {
      display: flex;
      justify-content: flex-end;
      height: 1rem;
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 1;
   }

   .product-card-content {
      display: flex;
      flex-direction: row;
      width: 100%;
   }

   .product-card-image,
   .product-card-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 60px;
      min-height: 60px;
      max-width: 60px;
      min-width: 60px;
   }

   .product-card-image-container [data-flight-image-placeholder] img,
   .product-card-image-container [data-flight-image] img {
      margin-top: 0px;
      padding: 0px;
   }

   .product-card-information {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      flex: 1;
      padding: 1.5rem 0.5rem 0.5rem 1rem;
      position: relative;
      min-width: 0;
      /* gap: 0.25rem; */
   }

   .product-card-title,
   .product-card-title a {
      margin-right: 0;
      display: block;
      word-wrap: break-word;
      word-break: normal;
      font-family: var(--font-industry);
      font-weight: 700;
      color: black;
   }

   .price-button-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 0;
   }

   .price-container {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
   }

   .sticker {
      height: 20px;
      @media (min-width: 400px) {
         height: 23px;
      }
      ${theme.above.lg} {
         height: unset;
      }
   }

   .campaign-sticker {
      height: 19px;
      @media (min-width: 400px) {
         height: 21px;
      }
      ${theme.above.lg} {
         height: unset;
      }
   }

   .product-card-button-container {
      position: static;
      transform: translateY(-2px);
      display: flex;
      align-items: flex-start;
   }

   .product-card-cart-button {
      padding: 0.375rem !important;
      border-radius: 50px;
      svg {
         width: 22px;
         height: 22px;
      }
      &:hover svg {
         transform: translate(0.1rem, 0);
      }
      ${theme.below.md} {
         padding: 0.375rem !important;
      }
   }

   .shipping-estimate-message {
      font-size: 12px;
      font-weight: 400;
      font-family: var(--font-industry);
      font-style: italic;
      transform: translateY(-1px);
      ${theme.below.md} {
         font-size: 12px;
      }
   }
`
const ProductSearchCardHorizontalSmall = ({
   product,
   onNavigationClick,
   productSettings,
}) => {
   const { selectedChannel } = useContext(ChannelContext)
   const { addProductToCart } = useAddToCart()
   const t = useIntl()
   const history = useHistory()
   let {
      title,
      articleNumber,
      imageUrl,
      url,
      isPreBook,
      buyable,
      hasVariants,
      onSale,
      isNew,
      isPackage,
      onlyForSaleInStore,
      // brandName,
      // inStock,
   } = UseFormatProduct(product)
   
   const {
      campaignSticker,
      newSticker,
      packageSticker,
      preBookCampaignSticker,
   } = getStickersFromProductSettings(productSettings)

   const buyableValue = isPreBook ? true : (onlyForSaleInStore ? false : buyable)

   // Only show shipping estimate message for Sweden
   const showShippingEstimateMessage = buyableValue && !isPreBook && selectedChannel?.id === 1
   const shippingEstimateMessage = showShippingEstimateMessage ? getShippingEstimateMessage(selectedChannel?.id, false) : ''

   const addToCart = e => {
      e.preventDefault()
      if (!buyableValue) {
         return
      }
      addProductToCart(product, articleNumber, 1, isPackage)
   }

   let strippedUrl = stripQueryParamFromUrl(url ?? '', 'att')

   const handleContainerClick = e => {
      // Prevent navigation if clicking on a button (like "Add to Cart")
      if (e.target.closest('.product-card-cart-button')) return
      // Prevent navigation if selecting text
      if (window.getSelection().toString().length > 0) return
      e.preventDefault()
      onNavigationClick?.()
      history.push(strippedUrl)
   }

   const getButtonColor = () => {
      if (onlyForSaleInStore) return 'grey'
      if (isPreBook) return 'yellow'
      if (!buyableValue) return 'grey'
      return 'green'
   }

   const renderButton = () => {
      const buttonProps = {
         size: 'medium',
         styleType: 'solid',
         color: getButtonColor(),
         icon: true,
         className: 'product-card-cart-button',
      }

      if (onlyForSaleInStore) {
         return (
            <Link to={strippedUrl} onClick={onNavigationClick}>
               <VbButton {...buttonProps}>
                  {/* {t('only for sale in store')} */}
                  <ArrowRight strokeWidth={2.0} />
               </VbButton>
            </Link>
         )
      }

      // If has variants or not buyable, show arrow and navigate
      if (hasVariants || !buyableValue) {
         return (
            <Link to={strippedUrl} onClick={onNavigationClick}>
               <VbButton {...buttonProps}>
                  <ArrowRight strokeWidth={2.0} />
               </VbButton>
            </Link>
         )
      }
      // Otherwise show cart icon and add to cart
      return (
         <VbButton {...buttonProps} onClick={addToCart}>
            <CartAlt strokeWidth={2.0} />
         </VbButton>
      )
   }

   return (
      <ProductCardContainer
         href={strippedUrl}
         className="product-card-horizontal"
         onClick={handleContainerClick}
         style={{ cursor: 'pointer' }}
      >
         <div className="product-card-content">
            <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="product-card-image-container">
                  <Image
                     className="product-card-image"
                     src={imageUrl}
                     aspect="1:1"
                  ></Image>
               </div>
            </div>
            <div className="product-card-information">
               <div className="stickers-container">
                  <div>
                     {campaignSticker && onSale && isPreBook && (
                        <img
                           className="img-fluid sticker"
                           alt="Campaign sticker"
                           src={preBookCampaignSticker}
                        />
                     )}
                     {campaignSticker && onSale && !isPreBook && (
                        <img
                           className="img-fluid campaign-sticker"
                           alt="Campaign sticker"
                           src={campaignSticker}
                        />
                     )}
                     {newSticker && isNew && (
                        <img
                           className="img-fluid sticker"
                           alt="New sticker"
                           src={newSticker}
                        />
                     )}
                     {packageSticker && isPackage && (
                        <img
                           className="img-fluid sticker"
                           alt="Package sticker"
                           src={packageSticker}
                        />
                     )}
                  </div>
               </div>
               <div className="product-card-title">{title} </div>
               <div className="price-button-container position-relative">
                  <div className="price-container">
                     <PriceSectionWithLabel
                        label={hasVariants ? t('from') : ''}
                        product={product}
                        selectedChannel={selectedChannel}
                     ></PriceSectionWithLabel>
                     {showShippingEstimateMessage && (
                        <span className="shipping-estimate-message">
                           {shippingEstimateMessage}
                        </span>
                     )}
                  </div>

                  <div className="product-card-button-container">
                     {renderButton()}
                  </div>
               </div>
            </div>
         </div>
      </ProductCardContainer>
   )
}

export default ProductSearchCardHorizontalSmall
